import React from "react";
import StudioDetailsPageSiteSpecific from "./StudioDetailsPageSiteSpecific";
import BaseSectionHeader from "../../../../components/BaseSectionHeader/BaseSectionHeader";

const TrentonDucatiOriginals = () => {
	return (
		<div className="MainDashboardView">
			<div style={{ margin: '1em' }}>
				<BaseSectionHeader primaryText='TD Originals' />
			</div>

			<StudioDetailsPageSiteSpecific
				title="nasty daddy"
				studioId="21606"
				studioName="nasty-daddy"
				numberOfItems={12}
			/>

			<StudioDetailsPageSiteSpecific
				studioId="22981"
				studioName="trentonducati"
				title="More from ducati studios"
				numberOfItems={12}
			/>

			<StudioDetailsPageSiteSpecific
				studioId="22698"
				studioName="gentlemens-closet"
				title="gentlemen's closet"
				numberOfItems={12}
			/>

			<StudioDetailsPageSiteSpecific
				studioId="23348"
				studioName="trailer-trash-boys"
				title="Trailer Trash Boys"
				numberOfItems={12}
			/>
		</div>
	);
};

export default TrentonDucatiOriginals;
