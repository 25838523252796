import React, { PureComponent } from 'react';
import { Link, navigate } from "@reach/router";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BrowseNavigation from "../../../components/BrowseNavigation/BrowseNavigation";
import MainSearch from "../../../components/MainSearch/MainSearch";
import Arrow from '../../../components/Arrow/Arrow';
import {
  showMyAccountPage,
} from "../../../services/navigation/navigation.service";
import { isUserLoggedIn, singleSignOff } from "../../../services/token-service/token.service";
import {
  getFreeRoute,
  getOriginalsPageRoute,
  //getStudioDetailsRoute,
  getTop10PageRoute,
  //getTrentonDucatiAllMoviesRoute,
  routes
} from "../../../services/navigation/navigation.service.routes";
import { isWeb, matchMediaRule } from "../../../services/util-service/util.service";

import { MainDashboardController } from "../MainDashboardController";
import { SignInController } from "../../../controllers/sign-in-controller/sign-in.controller";
import { UsersController } from "../../../controllers/users-controller/users.controller";

import { createJoinLinkFromLastCode, links } from "../../../links";

import './MainDashboardHeader.scss';
import { RenderConditionalComponent, whiteLabelPropertyID, PROPERTY_ID } from '../../../components/RenderConditionalComponent/RenderConditionalComponent';

import ApplicationLogo from "../../../components/ApplicationLogo/ApplicationLogo";
import UserAction from "../../../components/UserAction/UserAction";
import { getUserNATSCode } from '../../../services/nats-service/nats.service';


class MainDashboardHeader extends PureComponent {

  contentScrollListenerSet = false;

  previousLocation;

  state = {
    desktopSearchVisible: false,
    searchVisible: false,
    userStateChange: undefined
  };

  subscriptions = {};

  componentDidMount() {
    this.subscriptions.userStateChange = UsersController.userStateChange.subscribe(this.onUserStateChange);
    this.subscriptions.mainDashboardClick = MainDashboardController.mainDashboardClick.subscribe(this.onMainDashboardClick);
    singleSignOff(this.onUserStateChange, 'add');
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
    if (this.contentScrollListenerSet) {
      this.getContentRouter()?.removeEventListener('scroll', this.onUserScroll);
    }
    singleSignOff(this.onUserStateChange, 'remove');
  }

  checkIfTargetIsSearchElement = (mobileSearch, target) => {
    let isSearchElement = mobileSearch.className === target.className;
    if (!isSearchElement && target.parentElement) {
      isSearchElement = this.checkIfTargetIsSearchElement(mobileSearch, target.parentElement);
    }

    return isSearchElement;
  };

  getContentRouter = () => {
    const mainContent = document.getElementsByClassName('MainContainer')[0];
    return mainContent.childNodes[1];
  };

  login = () => {
    SignInController.showSignInDialog();
  };

  logout = () => {
    UsersController.signOutUser(this.props.t('MainDashboardHeader.loginOutMsg'));
  };

  onMainDashboardClick = (event) => {
    const { searchVisible } = this.state;
    const mobileSearch = document.getElementsByClassName('MobileSearchWrapper')[0];
    if (mobileSearch) {
      if (event.target.className.indexOf('SearchResultItem') === -1) {
        const isSearchElement = this.checkIfTargetIsSearchElement(mobileSearch, event.target);
        if (!isSearchElement) {
          const toggleSearch = event.target.classList.contains('fa-search') || searchVisible ? false : true;
          this.setSearchVisible(toggleSearch);
        }
      } else {
        this.setSearchVisible(false);
      }
    } else {
      const searchVisible = event.target.classList.contains('fa-search');
      this.setSearchVisible(searchVisible);
    }
  };

  onUserScroll = () => {
    if (this.state.searchVisible) {
      this.setSearchVisible(false);
    }
  };

  onUserStateChange = () => {
    this.setState({
      userStateChange: new Date().getTime()
    });
  };

  renderMobileMenu = () => {
    const { toggleMobileMenu } = this.props;
    return (
      <i className="MobileMenuBtn fa fa-bars"
        onClick={toggleMobileMenu} />
    );
  };

  renderMobileSearch = () => {
    let view = null;
    if (!isWeb()) {
      const { searchVisible } = this.state;
      if (searchVisible) {
        view = (
          <div className="MobileSearchWrapper">
            <div className="MobileSearchWrapperInner">
              {this.renderSearchEl()}
            </div>
          </div>
        );
      }
    }
    return view;
  };

  renderMobileSearchTrigger = () => {
    return (
      <div className="searchButton MobileSearchBtn" onClick={this.toggleMobileSearch}>
        <i className="fa fa-search" />
      </div>
    );
  };

  renderSearch = () => {
    const { primaryNavigation } = this.props;
    return primaryNavigation ? null : this.renderSearchEl();
  };

  toggleSearchFn = (newState) => {
    this.setState({
      desktopSearchVisible: !newState,
      searchVisible: !newState
    })
  };

  renderSearchEl = () => {
    const { searchOptions, handleSearchDropdownValue } = this.props;
    return (
      <MainSearch
        toggleSearchFn={this.toggleSearchFn}
        searchOptions={searchOptions}
        handleSearchDropdownValue={handleSearchDropdownValue}
      />
    );
  };

  //trenton nav
  // renderTrentonSpecialNavigation = () => {
  //   const navCls = "NavBar-Btn";
  //     return (
  //         <div className="SpecialHeaderOptions">
  //           <div>
  //           <Link className={navCls}
  //                 to={getTrentonDucatiMoviesRoute()}>
  //        TrentonDucati Movies</Link>
  //           </div>
  //           <div>
  //             <Link className={navCls}
  //                       to={getTrentonDucatiOriginalsRoute()}>
  //               TrentonDucati Originals
  //           </Link>
  //             </div>
  //           <div>
  //             <Link className={navCls}
  //                   to={getMoreFromTrentonDucatiRoute()}>
  //               More From Trenton Ducati
  //             </Link>
  //           </div>
  //           <div>
  //             <Link className={navCls}
  //                   to={getTrentonDucatiMoviesRoute()}>
  //               TrentonDucati Movies</Link>
  //           </div>
  //         </div>
  //     );
  //   };


  //end trenton nav

  renderSecondaryNavigation = () => {
    const { renderHeaderMenu, browseConfig, t, starsPageEnabled } = this.props;
    const { desktopSearchVisible } = this.state;
    const navCls = "NavBar-Btn";
    const headerOptions = (
      <div className="HeaderOptions">
        {!desktopSearchVisible ? (
          <>
            {/*
            //remove trenton drop down
            <BrowseNavigation
              className={navCls}
              title={'Trenton Ducati Movies'}
              browseConfig={[{
                link: getStudioDetailsRoute(22981, 'trentonducati'),
                text: 'Trenton Ducati Originals'
              }, {
                link: getMoreFromTrentonDucatiRoute(),
                text: 'More From Trenton Ducati'
              }, {
                link: getTrentonDucatiAllMoviesRoute(),
                text: 'ALL Trenton Ducati Movies'
              }]}

              starsPageEnabled={0}
              key={'browse-trenton'}
            />
            */}

            <BrowseNavigation
              className={navCls}
              browseConfig={browseConfig}
              starsPageEnabled={starsPageEnabled}
              baseText={t('BrowseNavigation.browse')}
            />

            <Link
              className={navCls}
              to={routes.justAdded}
              data-text={t('MainDashboardHeader.justAdded')}>
              {t('MainDashboardHeader.justAdded')}
            </Link>

            <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}>
              <Link className={navCls}
                to={getTop10PageRoute()}
                data-text={t('MainDashboardHeader.top10')}>
                {t('MainDashboardHeader.top10')}
              </Link>
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.huntForMen]}>
              <Link
                className={navCls}
                key="originals"
                to={getOriginalsPageRoute()}
                data-text={t('MainDashboardHeader.originals')}>
                {t('MainDashboardHeader.originals')}
              </Link>
            </RenderConditionalComponent>



            <RenderConditionalComponent ids={[PROPERTY_ID.trentonDucati]}>
              <BrowseNavigation
                className={navCls}
                browseConfig={[
                  {
                    link: routes.trentonducatioriginals,
                    text: t("MainDashboardHeader.tdoriginals")
                  },
                  {
                    link: getOriginalsPageRoute(),
                    text: t('MainDashboardHeader.nsoriginals')
                  }]}
                baseText={t('MainDashboardHeader.originals')}
              />
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.huntForMen]}>
              <a className={navCls}
                href={links.zine}
                rel="noopener noreferrer"
                key="zine"
                target="_blank">
                HUNT eZine
              </a>
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}>
              <a className={navCls}
                href={links.live}
                rel="noopener noreferrer nofollow"
                target="_blank"
                data-text={t('MainDashboardHeader.live')}>
                {t('MainDashboardHeader.live')}
              </a>
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.nakedSword]}>
              <Link
                className={navCls}
                onClick={this.notifyScrollTop}
                key="free"
                to={getFreeRoute()}
                data-text={t('MainDashboardHeader.free')}>
                {t('MainDashboardHeader.free')}
              </Link>
            </RenderConditionalComponent>
          </>
        ) : (
          <i className="HeaderNavCollapseIndicator fas fa-bars" />
        )}
        {this.renderSearchEl()}
      </div>
    );

    if (renderHeaderMenu || browseConfig?.length) {
      return headerOptions;
    }

    return null;
  };
  renderSeparator = () => <div className="Separator">|</div>;

  renderUserActions = () => {
    const userLoggedIn = isUserLoggedIn();
    const { t } = this.props;
    return (
      <div className="UserActions">
        {userLoggedIn ? (
          <UserAction text={t('MainDashboardHeader.signOut')} onClick={this.logout} />
        ) : (
          <UserAction text={t('MainDashboardHeader.joinNow')} onClick={() => {
            // get latest valid code
            const getStoredCode = getUserNATSCode();
            const joinURL = createJoinLinkFromLastCode(getStoredCode);
            navigate(joinURL);
          }} />
        )}
        {this.renderSeparator()}
        {userLoggedIn ? (
          <UserAction text={t('MainDashboardHeader.myAccount')} onClick={this.showMyAccount} />
        ) : (
          <div onClick={this.login} className="LoginWrapper">
            <UserAction text={t('MainDashboardHeader.login')} />
            <Arrow />
          </div>
        )}
      </div>
    );
  };

  setSearchVisible = (searchVisible) => {
    this.setState({ searchVisible }, this.toggleScrollListener);
  };

  showMyAccount = () => {
    showMyAccountPage();
  };

  toggleMobileSearch = () => {
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }

    if (matchMediaRule('max-width: 960px')) {
      this.setState(prevState => {
        return {
          searchVisible: !prevState.searchVisible
        };
      });
    }
  };

  toggleScrollListener = () => {
    if (!this.contentScrollListenerSet) {
      this.contentScrollListenerSet = true;
      const contentRouter = this.getContentRouter();
      contentRouter?.addEventListener('scroll', this.onUserScroll);
    }
  };

  renderBlurBackgroundMobile = () => {
    const { searchVisible } = this.state;
    if (!searchVisible || isWeb()) {
      return null;
    }
    return <div className="BackdropFilterMobile"></div>;
  }

  render() {
    const { className, primaryNavigation } = this.props;
    const classes = ['MainDashboardHeader'];
    const nakedSwordStyles = whiteLabelPropertyID === PROPERTY_ID.nakedSword || whiteLabelPropertyID === PROPERTY_ID.guerillaPorn ? 'nakedSword-styles' : '';

    if (primaryNavigation) {
      classes.push('Primary');
    }
    if (className) {
      classes.push(className);
    }
    return (
      <div className={classes.join(' ')}>
        <div className={`Inner ${nakedSwordStyles}`}>
          <div className="Section">
            <ApplicationLogo />

            {/* {this.renderTrentonSpecialNavigation()} */}
            {this.renderSecondaryNavigation()}
          </div>
          <div className="Section">
            {this.renderUserActions()}
            {this.renderMobileSearchTrigger()}
            {this.renderMobileMenu()}
          </div>
        </div>
        {this.renderMobileSearch()}
        {this.renderBlurBackgroundMobile()}
      </div>
    );
  }
}

MainDashboardHeader.propTypes = {
  className: PropTypes.string,
  browseConfig: PropTypes.array,
  searchOptions: PropTypes.array,
  primaryNavigation: PropTypes.bool,
  toggleMobileMenu: PropTypes.func,
  starsPageEnabled: PropTypes.number,
  handleSearchDropdownValue: PropTypes.func
};

export default withTranslation()(MainDashboardHeader);