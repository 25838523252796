import { navigate } from "@reach/router";
import {
	getApplicationStartRoute,
	getHelpRoute,
	getJustAddedPageRoute,
	getMemberBenefitsPageRoute,
	getMyAccountRoute,
	getMyDealsRoute,
	getMemberDealsRoute,
	getNewsLetterRoute,
	getOriginalsPageRoute,
	getPrivacyPolicyRoute,
	getTermsOfUseRoute,
	getThemesRoute,
	getTop10PageRoute,
	getThemeDetailsRoute,
	getMovieDetailsPageRoute,
	getStudioDetailsRoute,
	getWeekendsPageRoute,
	getMask4MaskRoute,
	getParty20thRoute,
	getSceneDetailsPageRoute,
	getDirectorDetailsPageRoute,
	getMoviePlaylistRoute,
	getScenePlaylistRoute,
	getCustomPlaylistRoute,
	getJoinLinkRoute,
	getCompliancePageRoute,
	// getOriginalsMoviesPageRoute,
	// getOriginalsScenesPageRoute,
	getFavoritesPageRoute,
	getStreamBlockedRoute,
	getForgotPasswordPageRoute,
	getStreamateRoute,
	getLiveRoute,
	getFleshJackPageRoute,
	getSignInPageRoute,
	getViewingHistoryPageRoute,
	getSexActDetailsRoute,
	getStarLetterListRoute,
	getStarMoviesRoute,
	getStarScenesRoute,
	getTrentonDucatiAllMoviesRoute,
	getTrentonDucatiOriginalsRoute,
	getMoreFromTrentonDucatiRoute,
	getFeedbackFormRoute,
	getDirectorScenesRoute,
	//getMXPineapple2022Route,
	getFreeRoute,
	getFreeRideOrDieBonusVideoRoute,
	getFreeRideOrDieBonusBehindScenesRoute,
	getFreeRideOrDieBonusHadItComingRoute,
	getFreeRideOrDieBonusMeetTheWardenRoute,
	getGiftRoute,
	//getNewPromoSmpOneRoute,
	//getNewPromoSmpTwoRoute,
	getDirectorMoviesRoute,
	getMostWatchedPageRoute
} from "./navigation.service.routes";
import { parseBannerUrl } from "../banners-service/banners.service";

export const showCompliancePage = () => navigate(getCompliancePageRoute());



export const showDirectorDetailsPage = (directorId, directorName) =>
	navigate(getDirectorDetailsPageRoute(directorId, directorName));

export const showMovieDetailsPage = (movieId, movieName) =>
	navigate(getMovieDetailsPageRoute(movieId, movieName));

export const showSceneDetailsPage = (movieId, movieName, sceneId) =>
	navigate(getSceneDetailsPageRoute(movieId, movieName, sceneId));

export const showJustAdded = () => navigate(getJustAddedPageRoute());

export const showJustAddedMovies = () => navigate(getJustAddedPageRoute());

export const showJustAddedMoviesPage = (page, sortBy) =>
	navigate(getJustAddedPageRoute() + `?content=Movies&sort=${sortBy}&page=${page}`);

export const showJustAddedScenes = () => navigate(getJustAddedPageRoute());

export const showJustAddedScenesPage = (page, sortBy) =>
	navigate(getJustAddedPageRoute() + `?content=Scenes&sort=${sortBy}&page=${page}`);

export const showMostWatchedMovies = () => navigate(getMostWatchedPageRoute());

export const showMostWatchedMoviesPage = (page, sortBy) =>
	navigate(getMostWatchedPageRoute() + `?content=Movies&sort=${sortBy}&page=${page}`);

export const showMostWatchedScenes = () => navigate(getMostWatchedPageRoute());

export const showMostWatchedScenesPage = (page, sortBy) =>
	navigate(getMostWatchedPageRoute() + `?content=Scenes&sort=${sortBy}&page=${page}`);

export const showTop10 = () => navigate(getTop10PageRoute());

export const showOriginalsPage = () => navigate(getOriginalsPageRoute());

// export const showOriginalsMoviesPage = (page, sortBy) =>
// 	navigate(getOriginalsMoviesPageRoute(page, sortBy));

// export const showOriginalsScenesPage = (page, sortBy) =>
// 	navigate(getOriginalsScenesPageRoute(page, sortBy));

export const showApplicationStart = () => navigate(getApplicationStartRoute());

export const showStudioDetailsPage = (id, name) => navigate(getStudioDetailsRoute(id, name));

// export const showStudioDetailsPageQuery = (id, name, content, sortBy, page) =>
// 	navigate(getStudioDetailsRoute(id, name) + `?content=${content}&sort=${sortBy}&page=${page}`);

export const showThemesPage = () => navigate(getThemesRoute());

export const showThemeDetailsPage = (id, name) => navigate(getThemeDetailsRoute(id, name));

export const showSexActDetailsPage = (id, name) => navigate(getSexActDetailsRoute(id, name));

export const showMoviePlaylistPage = (playlistId, name) =>
	navigate(getMoviePlaylistRoute(playlistId, name));

export const showScenePlaylistPage = (playlistId, name) =>
	navigate(getScenePlaylistRoute(playlistId, name));

export const showCustomPlaylistPage = (playlistId, name) =>
	navigate(getCustomPlaylistRoute(playlistId, name));

export const showMyAccountPage = () => navigate(getMyAccountRoute());

export const showNewsLetterPage = () => navigate(getNewsLetterRoute());

export const showHelpPage = () => navigate(getHelpRoute());

export const showTermsOfUsePage = () => navigate(getTermsOfUseRoute());

export const showPrivacyPolicyPage = () => navigate(getPrivacyPolicyRoute());

export const showMyDeals = () => navigate(getMyDealsRoute());

export const showMemberDeals = () => navigate(getMemberDealsRoute());

//export const showMXPineapple2022 = () => navigate(getMXPineapple2022Route());

export const showFree = () => navigate(getFreeRoute());

export const showFreeRideOrDieBonusVideo = () => navigate(getFreeRideOrDieBonusVideoRoute());

export const showFreeRideOrDieBonusBehindScenes = () => navigate(getFreeRideOrDieBonusBehindScenesRoute());

export const showFreeRideOrDieBonusHadItComing = () => navigate(getFreeRideOrDieBonusHadItComingRoute());

export const showFreeRideOrDieBonusMeetTheWarden = () => navigate(getFreeRideOrDieBonusMeetTheWardenRoute());


export const showGift = () => navigate(getGiftRoute());

export const showMemberBenefitsPage = () => navigate(getMemberBenefitsPageRoute());

export const showForgotPasswordPage = () => navigate(getForgotPasswordPageRoute());

export const showStreamate = () => navigate(getStreamateRoute());

export const showLive = () => navigate(getLiveRoute());

export const showStreamBlockedPage = () => navigate(getStreamBlockedRoute());

// export const showWeekendsPage = () => navigate(getWeekendsPageRoute());

export const showMask4MaskPage = () => navigate(getMask4MaskRoute());

export const showParty20thPage = () => navigate(getParty20thRoute());

export const showTrentonDucatiAllMoviesRoute = () => navigate(getTrentonDucatiAllMoviesRoute());

export const showMoreFromTrentonDucatiRoute = () => navigate(getMoreFromTrentonDucatiRoute());

export const showFeedbackFormPageRoute = () => navigate(getFeedbackFormRoute());

export const showTrentonDucatiOriginalsRoute = () => navigate(getTrentonDucatiOriginalsRoute());

export const showEntityDetailsPage = (url, target = "_self") => {
	const {
		movieId,
		movieName,
		moviePlaylistId,
		playlistName,
		sceneIndex,
		scenePlaylistId,
		studioId,
		studioName,
		themeId,
		themeName,
	} = parseBannerUrl(url);

	if (movieId) {
		if (sceneIndex !== undefined) {
			navigate(getSceneDetailsPageRoute(movieId, movieName, sceneIndex), target);
		} else {
			navigate(getMovieDetailsPageRoute(movieId, movieName), target);
		}
	} else if (url === "/weekends") {
		navigate(getWeekendsPageRoute(), target);
	} else if (studioId) {
		navigate(getStudioDetailsRoute(studioId, studioName), target);
	} else if (themeId) {
		navigate(getThemeDetailsRoute(themeId, themeName), target);
	} else if (moviePlaylistId) {
		navigate(getMoviePlaylistRoute(moviePlaylistId, playlistName), target);
	} else if (scenePlaylistId) {
		navigate(getScenePlaylistRoute(scenePlaylistId, playlistName), target);
	} else {
		window.open(url, target);
	}
};

export const showJoinNowPage = () => navigate(getJoinLinkRoute());

export const showFavoritesPage = () => navigate(getFavoritesPageRoute());

export const showFleshJackPageRoute = () => navigate(getFleshJackPageRoute());

export const showSignInPage = () => navigate(getSignInPageRoute());

export const showViewingHistoryPage = () => navigate(getViewingHistoryPageRoute());

export const showStarLetterListPage = (letter) => navigate(getStarLetterListRoute(letter));

export const showStarMovies = (starId, starName, page, sortBy) =>
	navigate(getStarMoviesRoute(starId, starName, page, sortBy));

export const showStarScenes = (starId, starName, page, sortBy) =>
	navigate(getStarScenesRoute(starId, starName, page, sortBy));

export const getURLPath = () => window?.location?.pathname || "/";

export const showDirectorMovies = (id, name, page, sort) =>
	navigate(getDirectorMoviesRoute(id, name, page, sort));

export const showDirectorScenes = (id, name, page, sort) =>
	navigate(getDirectorScenesRoute(id, name, page, sort));